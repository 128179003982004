import { useEffect } from "react";
import { useLocation } from "react-router";

export const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return <>{children}</>;
};
