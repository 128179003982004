import { combineReducers } from "redux";
import authReducer from "./auth";
import modalReducer from "./modal";
import toastsReducer from "./toasts";

const rootReducer = combineReducers({
  modal: modalReducer,
  auth: authReducer,
  toasts: toastsReducer,
});

export default rootReducer;
