import React, { useState } from "react";
import { hideModal, showModal } from "../redux/actions/modal";
import { CREATE_ACCOUNT_MODAL, SUCCESS } from "../extras/constants";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/actions/toasts";
import { Formik } from "formik";
import * as Yup from "yup";
import { loginUser } from "../redux/actions/auth";

const LoginSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(
      /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
      "Invalid phone number"
    )
    .required("Required"),
});
const LoginModal = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values) => {
    values.mobileNumber = parseInt(values.mobileNumber.replace(/\s/g, ""));
    dispatch(loginUser(values, setIsLoading));
  };
  return (
    <>
      <div className="w-full bg-white p-5 rounded-xl">
        <div className="text-xl mb-4 font-bold text-black">Login</div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={LoginSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => {
            return (
              <div>
                <input
                  type="text"
                  name="mobileNumber"
                  placeholder="Contact Number"
                  onChange={handleChange("mobileNumber")}
                  className="appearance-none block w-full border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] text-black focus:border-[#C2E3F4]"
                />
                {touched.mobileNumber && errors.mobileNumber && (
                  <p className="text-red-500 ml-4">{errors.mobileNumber}</p>
                )}
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className={`appearance-none rounded-full  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-app-secondary   ${
                    isLoading ? "opacity-80 cursor-not-allowed" : ""
                  }`}
                >
                  Login
                </button>
                <p
                  className="text-center text-medium mt-7 cursor-pointer text-black"
                  onClick={() => {
                    dispatch(hideModal());
                    dispatch(
                      showModal({
                        modalType: CREATE_ACCOUNT_MODAL,
                        modalSubTitle: "",
                      })
                    );
                  }}
                >
                  New here?{" "}
                  <span className="text-indigo-700 font-bold">
                    Create an Account
                  </span>
                </p>
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default LoginModal;

const initialValues = {
  mobileNumber: "",
};
