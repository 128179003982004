import { useField } from "formik";
import ErrorBox from "./ErrorBox";

const Input = ({
  type,
  id,
  name,
  placeholder,
  classes,
  readonly = false,
  autoFocus = false,

  ...props
}) => {
  //   field -> { name: string, value: string, onChange: () => {}, onBlur: () => {} }
  //   meta -> { touched: boolean, error: string, ... }
  const [field, meta] = useField({ name, type });
  return (
    <div className={`flex flex-col w-full ${classes}`}>
      <input
        disabled={readonly}
        readOnly={readonly}
        autoFocus={autoFocus}
        name={name}
        placeholder={placeholder}
        className={`
          appearance-none block w-full border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] text-black focus:border-[#C2E3F4]
          ${
            meta.touched && meta.error
              ? "border-red-400 "
              : "border-slate-200 focus:border-primary"
          }     disabled:opacity-80 disabled:cursor-not-allowed`}
        {...field}
        {...props}
        id={id}
        type={type}
      />

      {meta.touched && meta.error && <ErrorBox msg={meta.error} />}
    </div>
  );
};

export default Input;
