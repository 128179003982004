import { getTokens } from "../../extras/utils";
import {
  GET_CURRENT_MATCH,
  GET_CURRENT_USER,
  GET_PLAYER_DETAIL,
  LOGIN_USER,
  LOGOUT_USER,
} from "../actions/types";

const initialState = {
  accessToken: getTokens().accessToken,
  isLoggedIn: false,
  refreshToken: getTokens().refreshToken,
  user: null,
  currentMatch: null,
  playerDetail: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        accessToken: action.payload,
        isLoggedIn: true,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        accessToken: null,
        user: null,
        isLoggedIn: false,
      };
    case GET_CURRENT_MATCH:
      return {
        ...state,
        currentMatch: action.payload,
      };
    case GET_PLAYER_DETAIL:
      return {
        ...state,
        playerDetail: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
