import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DEFAULT, ERROR, INFO, SUCCESS, WARNING } from "../extras/constants";

const Toasts = ({ toasts }) => {
  toasts &&
    toasts.forEach((t) => {
      if (t.kind === ERROR)
        toast.error(t.msg, {
          autoClose: 1800,
          toastId: t.msg,
        });
      if (t.kind === SUCCESS)
        toast.success(t.msg, {
          autoClose: 1800,
          toastId: t.msg,
        });
      if (t.kind === INFO)
        toast.info(t.msg, {
          autoClose: 1800,
          toastId: t.msg,
        });
      if (t.kind === WARNING)
        toast.warn(t.msg, {
          autoClose: 1800,
          toastId: t.msg,
        });
      if (t.kind === DEFAULT)
        toast(t.msg, {
          autoClose: 1800,
          toastId: t.msg,
        });
    });

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    toasts: state.toasts.toasts,
  };
};

export default connect(mapStateToProps)(Toasts);
