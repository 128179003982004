// modal
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

// auth
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";

// toasts
export const ADD_TOAST = "ADD_TOAST";
export const EMPTY_TOASTS = "EMPTY_TOASTS";

// matches
export const GET_CURRENT_MATCH = "GET_CURRENT_MATCH";

//player 
export const GET_PLAYER_DETAIL = "GET_PLAYER_DETAIL";
