import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_MODAL } from "../extras/constants";
import { hideModal, showModal } from "../redux/actions/modal";

import { Formik } from "formik";
import * as Yup from "yup";
import { signupUser } from "../redux/actions/auth";

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  gender: Yup.string()
    .oneOf(
      ["MALE", "FEMALE", "OTHER"],
      "Please enter a valid value ( MALE, FEMALE, OTHER )"
    )
    .required("Required"),
  mobileNumber: Yup.string()
    .matches(
      /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/,
      "Invalid phone number"
    )
    .required("Required"),
  dateOfBirth: Yup.date().required("Date of Birth is Required"),
  jerseyNumber: Yup.string(),
});

const CreateAccountModal = () => {
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values) => {
    const postData = {
      dateOfBirth: new Date(values.dateOfBirth).toISOString(),
      mobileNumber: parseInt(values.mobileNumber.replace(/\s/g, "")),
      jerseyNumber: values.jerseyNumber,
      gender: values.gender,
      name: values.name,
    };

    dispatch(signupUser(postData, setIsLoading));
  };
  return (
    <>
      <div className="bg-white p-5 px-7 rounded-2xl">
        <div className="text-xl mb-4 font-bold text-black">
          Create your account
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={SignUpSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => {
            return (
              <div>
                <input
                  type="text"
                  name="name"
                  value={values.name}
                  placeholder="Name"
                  onChange={handleChange("name")}
                  className="appearance-none mt-5 block w-full border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] text-black focus:border-[#C2E3F4]"
                />
                {touched.name && errors.name && (
                  <p className="text-red-500 ml-4">{errors.name}</p>
                )}
                <input
                  type="text"
                  name="jerseyNumber"
                  value={values.jerseyNumber}
                  placeholder="Jersey Number (Optional)"
                  onChange={handleChange("jerseyNumber")}
                  className="appearance-none mt-5 block w-full border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] text-black focus:border-[#C2E3F4]"
                />
                {touched.jerseyNumber && errors.jerseyNumber && (
                  <p className="text-red-500 ml-4">{errors.jerseyNumber}</p>
                )}
                <select
                  name="gender"
                  value={values.gender}
                  onChange={handleChange("gender")}
                  placeholder="gender"
                  className="appearance-none bg-white text-black mt-5 block w-full border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4] "
                >
                  <option value="" selected hidden className="text-black">
                    Gender
                  </option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHERS">Others</option>
                </select>
                <input
                  type="date"
                  placeholder="Date Of Birth"
                  name="dateOfBirth"
                  aria-label="Date of Birth"
                  value={values.dateOfBirth}
                  aria-placeholder="Date of Birth"
                  onChange={handleChange("dateOfBirth")}
                  className="appearance-none mt-5 text-black bg-white block w-full border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4] "
                />
                {touched.dateOfBirth && errors.dateOfBirth && (
                  <p className="text-red-500 ml-4">{errors.dateOfBirth}</p>
                )}
                <input
                  type="text"
                  name="mobileNumber"
                  value={values.mobileNumber}
                  placeholder="Contact Number"
                  onChange={handleChange("mobileNumber")}
                  className="appearance-none mt-5 block w-full border-2 rounded-full py-2 px-4 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] text-black focus:border-[#C2E3F4] "
                />
                {touched.mobileNumber && errors.mobileNumber && (
                  <p className="text-red-500 ml-4">{errors.mobileNumber}</p>
                )}
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className={`appearance-none rounded-full  block w-full mt-4 -mb-2 bg-black border-0 py-2 focus:outline-none text-sm font-semibold text-app-secondary   ${
                    isLoading ? "opacity-80 cursor-not-allowed" : ""
                  }`}
                >
                  Create Account
                </button>
                <br />
                <p
                  className="text-center text-black text-medium mt-0 cursor-pointer"
                  onClick={() => {
                    dispatch(hideModal());
                    dispatch(
                      showModal({
                        modalType: LOGIN_MODAL,
                      })
                    );
                  }}
                >
                  Already have an account?{" "}
                  <span className="text-indigo-700 font-bold">Login</span>
                </p>
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const initialValues = {
  name: "",
  gender: "",
  mobileNumber: "",
  jerseyNumber: "",
  dateOfBirth: "",
};

export default CreateAccountModal;
