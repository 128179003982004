import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getPlayerDetail } from "../redux/actions/auth";
import SubmitBtn from "./formik components/SubmitBtn";
import Input from "./formik components/Input";
import { phoneRegExp } from "../extras/constants";

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Required"),
});
const PlayerExistsModal = () => {
  //   const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (values, setSubmitting) => {
    dispatch(getPlayerDetail(values.mobileNumber, setSubmitting));
  };

  return (
    <>
      <div className="w-full bg-white ">
        {/* <h2 className="text-xl mb-4 font-bold text-black capitalize">
          Find Existing Player by Contact Number
        </h2> */}
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, setSubmitting)
          }
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form>
                <Input
                  id={"mobileNumber"}
                  name={"mobileNumber"}
                  type={"text"}
                  placeholder={"Contact Number"}
                />

                <SubmitBtn
                  text={"Search"}
                  isSubmitting={isSubmitting}
                  containerClasses="mt-8 "
                  classes={"btn-primary"}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const initialValues = {
  mobileNumber: "",
};

export default PlayerExistsModal;
