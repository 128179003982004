import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";

function useGetMatch({ id, status }) {
  const query = new URLSearchParams();
  id && query.append("_id", id);
  status && query.append("status", status);

  const getMatch = async ({ queryKey }) => {
    const { data } = await axiosInstance.get(
      `/match/getMatchDetails?${query.toString()}`
    );
    return data.match;
  };

  const {
    data: dataMatch,
    error: errorMatch,
    isLoading: isLoadingMatch,
  } = useQuery(["getMatch", id, status], getMatch);

  return {
    dataMatch,
    errorMatch,
    isLoadingMatch,
  };
}

export default useGetMatch;
